<template>
  <div class="innerpage">
    <Header></Header>
    <v-container class="my-2">
      <v-row>
        <v-col>
          <h1>User Guide Video</h1>
          <v-divider class="mt-8 mb-3"></v-divider>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="7">
          <h3 class="mb-2">Sign Up and Onboard</h3>
          <video controls width="500">
            <source
              src="/assets/videos/001_whs_signup_and_onboard.mp4"
              type="video/mp4"
            />

            Sorry, your browser doesn't support embedded videos.
          </video>
          <h3 class="mt-6 mb-2">Profile, Logo, Account update</h3>
          <video controls width="500">
            <source
              src="/assets/videos/002_whs_profile_logo_account.mp4"
              type="video/mp4"
            />

            Sorry, your browser doesn't support embedded videos.
          </video>
          <h3 class="mt-6 mb-2">License and Subdomains</h3>
          <video controls width="500">
            <source
              src="/assets/videos/003_whs_multi-license_and_subdomains.mp4"
              type="video/mp4"
            />

            Sorry, your browser doesn't support embedded videos.
          </video>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header";
import OrgCoursesService from "../services/orgcourses.service";
import { Role } from "@/helpers/roles";

export default {
  components: {
    Header
  },
  data: () => ({
    courses: [],
    apiError: "",
    isLoaded: false,
    videoCode: "https://www.youtube.com/watch?v="
  }),

  computed: {
    isOrgAdmin() {
      let orgAdmin = false;
      this.currentUser.user.roles.forEach(item => {
        if (item.role_id === Role.OrgAdmin) {
          orgAdmin = true;
        }
      });
      return orgAdmin;
    },

    conditionsToDoCourses() {
      if (
        this.currentUser.user.workergroups[0] &&
        this.currentUser.user.workergroups[0].id &&
        this.currentUser.user.locations &&
        this.currentUser.user.locations.id
      ) {
        return false;
      } else {
        return true;
      }
    },

    currentUser() {
      return this.$store.state.auth.user;
    },

    orgId() {
      return this.$store.state.auth.user.user.organizations.id;
    }
  },
  created() {
    if (!this.currentUser) {
      this.$router.push("/login");
    } else {
      this.initialize();
    }
  },

  methods: {
    async initialize() {
      try {
        const response = await OrgCoursesService.get({
          orgId: this.orgId
        });
        this.courses = response.data.slice();
        this.isLoaded = true;
      } catch (e) {
        console.log("error getting courses", e);
      }
    }
  }
};
</script>
